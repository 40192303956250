import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>

    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section content">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                Hast du das Internet kaputt gemacht?
              </h2>
              <p>Diese Seite existiert nicht.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
